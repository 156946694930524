.main-alert {
  font-family: 'Roboto';
  background-color: $color-black-2;
  box-shadow: $main-box-shadow;
  overflow: hidden;
  border-radius: 0;

  &-header {
    font-size: 24px;
  }

  // &-body {
  // }
}

.section-details {
  @include flex-between();
  font-family: 'Oxanium';
  color: $color-white;
  flex-direction: column;

  &.with-description {
    margin-bottom: 24px;
  }

  &-header {
    display: flex;
    border-radius: 40px;
    border-image: url(../../assets/images/header-bg-image.png) 30 round;
    border-image-repeat: initial;
    border-width: 20px;
    border-style: solid;
    margin-bottom: 16px;

    &-text {
      font-size: 24px;
      text-align: center;
      border-image-width: auto;
      border-image-repeat: stretch;
      text-transform: uppercase;
      font-weight: 700;
      background: -webkit-linear-gradient(#43e4ff, #92eefd);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &-description {
    text-align: center;
    max-width: 65%;
  }

  @media (max-width: $screen-tablet) {
    &-description {
      max-width: unset;
    }
  }
}

.staking {
  &-tokens {
    .staking-token-group {
      flex-basis: 50%;

      &-data {
        margin-bottom: 8px;
      }
    }
  }

  @media (max-width: $screen-tablet) {
    &-tokens {
      flex-wrap: wrap;

      .staking-token-group {
        flex-basis: 100%;

        &:last-child {
          margin-top: 24px;
        }
      }
    }
  }
}

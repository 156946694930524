// COLORS
$main-border-color: #363439;

$color-dark: rgba(7, 24, 43, 0.8);
$color-dark-2: #07182b;
$color-dark-hover: #031f39;
$color-blue: #209ed9;
$color-blue-2: #097ec5;
$color-aquamarine: #74c9ce;
$color-cyan: #43e4ff;
$color-aquamarine-hover: #daf8f9;
$color-orange: #f15f24;
$color-geco: #e4a101;
$color-geco-hover: #ffce59;
$color-success: #73d720;
$color-error: #f12924;
$color-white: #ffffff;
$color-white-2: #edeaea;
$color-black: #010b1a;
$color-black-2: rgba(1, 11, 26, 0.9);
$color-green: #1cbe27;
// 264562 // hover selected

// PILLS
$color-pill-background: #010b1a;
$color-active-pill-background: #264562;
$color-pill-hover: #142e4a;

// SHADOWS
$main-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.5);

// BORDERS
$main-border-radius: 5px;

// TYPOGRAPHY
$font-extra-small: 12px;
// $line-extra-small: 14px;
$font-small: 14px;
// $line-small: 16px;
$font-medium: 16px;
// $line-medium: 19px;
$font-large: 19px;
// $line-large: 21px;
$font-extra-large: 21px;

// FONT
$font-light: 300;
$font-regular: 400;
$font-bold: 700;

// TRANSITIONS
$main-transition: all 0.3s ease 0s;

$header-height: 78px;
$footer-height: 150px;

// Z-INDEX
$z-bedrock: -1;
$z-bottom: 0;
$z-base: 10;
$z-pop: 100;
$z-overlay: 200;
$z-modal: 200;
$z-topmost: 9999;

// BREAKPOINTS
$screen-desktop: 1200px;
$screen-tablet: 768px;
$screen-mobile-big: 480px;

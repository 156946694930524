.playnity-input-wrapper {
  position: relative;
  display: flex;
  transform: skew(-16deg);
  background: $color-pill-background;
  border: 1px solid rgba(255, 255, 255, 0.1);

  input {
    &.playnity-input {
      transform: skew(16deg);
      margin: 0 6px;
      padding: 0 6px;
      background: $color-pill-background;
      border: none;
      color: $color-white;
      width: 100%;
      min-height: 42px;

      ::placeholder {
        color: $color-white;
        opacity: 0.6;
      }
    }
  }
}

.playnity-btn-wrapper {
  @include flex-centered();
  margin: 5px;
  flex-basis: 25%;
  position: relative;
  transform: skew(-16deg);
  background: transparent;
  border: 1px solid $color-geco;
  transition: $main-transition;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.aquamarine {
    border: 1px solid $color-aquamarine;

    button {
      &.playnity-btn-outline {
        color: $color-aquamarine;
      }
    }

    &:hover:not(.disabled) {
      background: $color-aquamarine;
    }
  }

  &:hover:not(.disabled) {
    background: $color-geco;

    button {
      &.playnity-btn-outline {
        color: $color-dark;
      }
    }
  }

  button {
    &.playnity-btn-outline {
      @include font-small();
      padding: 10px 0;
      color: $color-geco;
      transform: skew(16deg);
      background: transparent;

      &:before,
      &:after {
        content: none;
      }

      &:hover {
        [disabled] {
          background-color: transparent;
        }

        &:not([disabled]) {
          color: $color-dark;
        }
      }
    }
  }
}

.maintenance-button-wrapper {
  text-align: center;

  .btn {
    &.btn-style-two {
      &.maintenance-button {
        margin-top: 24px;
      }
    }
  }
}

.card {
  @include main-border();
  @include main-border-radius();
  box-shadow: $main-box-shadow;
  background-color: transparent;
  overflow: hidden;

  &-header {
    min-height: 78px;
    background: $color-dark;
    padding: 28px 44px;
  }

  &.loading {
    pointer-events: none;
  }

  &:not(.loading) {
    .card-header {
      cursor: pointer;

      &:hover {
        background-color: $color-dark-hover;
      }
    }
  }

  .collapse,
  .collapsing {
    border-top: 1px solid $main-border-color;
  }

  .card-body {
    @include dark-background-with-backdrop();
    padding: 28px 44px;
  }

  @media (max-width: $screen-tablet) {
    &-header {
      min-height: 56px;
      padding: 24px 15px;
    }

    .card-body {
      padding: 24px 15px;
    }
  }
}

.nav-pills {
  padding: 0 10px;
  .nav-item {
    flex-grow: 1;

    .nav-link {
      text-align: center;
      background: $color-pill-background;

      &:not(.disabled) {
        color: $color-white;
      }

      &:hover {
        position: relative;
        z-index: 1;
        opacity: 1;
        background-color: $color-dark-hover;
        box-shadow: $main-box-shadow;
        transform: scale(1.05);
      }
    }

    .nav-link.active,
    .show > .nav-link {
      color: $color-white;
      opacity: 1;
      background: $color-active-pill-background;
    }
  }
}

.progress {
  background-color: $color-pill-background;
  border: 1px solid $main-border-color;

  &-bar {
    &-striped {
      transform: scaleX(-1);
    }
  }
}

.modal {
  font-family: 'Roboto';

  &-content {
    background: $color-dark-2;
    color: $color-white;
    border-color: $color-aquamarine;
  }

  &-header {
    border-bottom: none;

    .close {
      opacity: 1;
      text-shadow: none;
      color: $color-white;
    }
  }
}

// TODO: Fix styles
.footer {
  background-color: $color-black;
  padding: 32px 0;
  font-size: $font-medium;

  &-chain-link {
    @include flex-centered();
  }

  &-chain-logo {
    height: 16px;
    margin-left: 8px;
  }

  .navigation {
    &-container {
      padding: 0;
      margin: 0;
    }

    &-link {
      font-family: 'Oxanium';
      text-transform: uppercase;
      font-size: $font-small;
      font-weight: $font-bold;
      display: inline-block;
      padding: 0 16px;
      text-align: center;

      a {
        text-decoration: none;
        color: $color-white-2;

        &:hover {
          color: $color-white;
          opacity: 0.6;
        }
      }
    }
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 25%;

    .description {
      margin-right: 8px;
    }

    &-link {
      display: flex;
      cursor: pointer;
      color: $color-white;
      font-size: 18px;

      &:hover {
        opacity: 0.6;
      }

      & + .social-link {
        margin-left: 8px;
      }
    }
  }

  .logo {
    flex-basis: 25%;

    .img {
      width: 200px;
    }
  }

  .copyright {
    width: 50%;
    color: $color-white;
    opacity: 0.6;
    padding-top: 16px;
  }

  @media screen and (max-width: $screen-tablet) {
    .logo,
    .navigation,
    .social,
    .copyright,
    .chain-link {
      display: flex;
      flex-basis: 100%;
      justify-content: center;
    }

    .navigation,
    .social,
    .chain-link {
      padding-top: 16px;
    }

    .footer,
    .logo,
    .navigation-container {
      flex-wrap: wrap;
    }

    .logo .description {
      width: 100%;
      text-align: center;
    }

    .navigation-link {
      width: 100%;

      & + .navigation-link {
        margin-top: 16px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .social {
      flex-basis: 100%;
    }
  }
}

@import 'variables';
@import 'helpers';
@import './mixins/index';
@import './elements/index.scss';
@import './components/index.scss';
@import './overrides/bootstrap-overrides.scss';
@import './overrides/geco-overrides.scss';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Oxanium', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-height: 100%;
}

.app-container {
  height: 100vh;
  // TODO: Fix problem with images
  background-color: $color-black;
  background-image: url(../assets/images/background-new.jpeg);
  background-attachment: scroll;
  overflow-y: scroll;
  background-size: cover;
  background-position: bottom;
}

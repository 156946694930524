.home {
  font-family: Roboto;

  &-header {
    text-align: center;

    &-logo {
      margin-bottom: 16px;
      align-self: center;

      img {
        width: 190px;
      }
    }

    &-text {
      text-align: center;
    }

    &-buttons {
      margin-top: 24px;
      display: flex;
      justify-content: space-around;
    }
  }

  &-token-info {
    margin-top: 24px;
    font-size: $font-medium;

    &-header {
      margin-right: 8px;
      word-break: break-all;
    }

    &-copy-icon {
      margin-left: 8px;
    }
  }

  @media (max-width: $screen-mobile-big) {
    &-header {
      &-buttons {
        margin-top: 0;
        flex-wrap: wrap;
        justify-content: unset;

        .btn.btn-style-two {
          margin-top: 24px;
          width: calc(100% - 40px);
        }
      }
    }

    &-token-info {
      &-header {
        display: block;
        width: 100%;
      }

      &-address {
        font-size: $font-extra-small;
      }

      &-copy-icon {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

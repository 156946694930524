.radio {
  &-label {
    cursor: pointer;
    position: relative;
    padding-left: 32px;
    font-size: 14px;
    user-select: none;
    color: $color-white;

    &:hover .radio-input {
      :not(:checked) {
        ~ .radio-checkmark {
          background-color: #ccc;
        }
      }
    }

    &.disabled {
      cursor: initial;
      color: $color-white;
      opacity: 0.6;
    }
  }

  &-input {
    cursor: pointer;
    position: absolute;
    opacity: 0;

    &:checked {
      ~ .radio-checkmark {
        background-color: $color-aquamarine;

        &:after {
          display: block;
        }
      }
    }
  }

  &-checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $color-white;
    }
  }
}

.hall-of-fame {
  &-items {
    @include flex-between();
    flex-wrap: wrap;
    font-family: 'Roboto';
    margin: -10px;

    @media (max-width: $screen-tablet) {
      justify-content: center;
    }
  }

  &-item {
    position: relative;
    user-select: none;
    padding: 10px;

    &-address {
      top: 55%;
      background: linear-gradient(
        262.86deg,
        #a7590a -38.7%,
        #5b3208 -14.83%,
        #7a4209 -2.33%,
        #934f09 11.31%,
        #a2560a 23.81%,
        #a7590a 34.04%,
        #f4a14b 74.95%
      );
    }

    &-exp {
      top: 75%;
      font-size: 10px;
      font-weight: $font-regular;
      background: linear-gradient(82.7deg, #a7590a -15.48%, #f4a14b 100%);
    }

    &-address,
    &-exp {
      left: 10px;
      right: 10px;
      position: absolute;
      text-align: center;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.app-layout {
  .app-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: $screen-mobile-big) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .app-content {
    box-sizing: border-box;
    padding-top: $header-height;
    min-height: calc(100vh - #{$footer-height});
  }

  .app-inner {
    background-color: $color-black-2;
    padding: 50px;
    border-radius: 5px;
    box-shadow: $main-box-shadow;

    @media (max-width: $screen-tablet) {
      border-radius: 0;
    }

    @media (max-width: $screen-mobile-big) {
      padding: 24px 16px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(
        270deg,
        rgba(67, 228, 255, 0) 20%,
        rgba(67, 228, 255, 0.333) 40%,
        $color-cyan 50%,
        rgba(67, 228, 255, 0.322767) 60%,
        rgba(67, 228, 255, 0) 80%
      );
      width: 100%;
      height: 5px;
      left: 0;
      z-index: 1;
    }

    &::before {
      top: 1px;
    }

    &::after {
      bottom: 1px;
    }
  }
}

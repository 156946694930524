$color-rank-1: #f1a642;
$color-rank-2: #dff1ff;
$color-rank-3: #e07240;
$color-rank-4: #8b9ad3;
$color-rank-5: #bf7526;
$color-rank-6: #59791f;

.leaderboard-table {
  .connected-address-anchor {
    margin-top: -$header-height;
    padding-bottom: $header-height;
    display: block;
    line-height: 0;
  }

  &-filter {
    margin-bottom: 24px;
  }

  &-header {
    font-size: $font-extra-small;
  }

  &-row,
  &-header {
    padding: 10px;

    .rank,
    .leaderboard-table-rank {
      flex-basis: 33.33%;
      min-width: 65px;
    }

    .address {
      flex-basis: 33.33%;
    }

    .value {
      text-align: right;
      flex-basis: 33.33%;
      white-space: nowrap;
    }

    @media (max-width: $screen-tablet) {
      .address {
        flex-basis: 100%;
      }
    }
  }

  &-row {
    min-height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 12px;
    border-radius: $main-border-radius;
    backdrop-filter: blur(4px);
    background-color: rgba(7, 24, 43, 0.8);

    &.rank-1 {
      color: $color-rank-1;
      border: 2px solid $color-rank-1;
    }

    &.rank-2 {
      color: $color-rank-2;
      border: 2px solid $color-rank-2;
    }

    &.rank-3 {
      color: $color-rank-3;
      border: 2px solid $color-rank-3;
    }

    &.rank-4 {
      color: $color-rank-4;
      border: 2px solid $color-rank-4;
    }

    &.rank-5 {
      color: $color-rank-5;
      border: 2px solid $color-rank-5;
    }

    &.rank-6 {
      color: $color-rank-6;
      border: 2px solid $color-rank-6;
    }

    &.connected {
      &.rank-1 {
        color: #c6400a;
        background: linear-gradient(180deg, #ffe769 -64.18%, #ffa200 110.45%);
      }

      &.rank-2 {
        color: #446581;
        background: linear-gradient(
          169.47deg,
          #ffffff -56.17%,
          #c9e8ff 126.29%
        );
      }

      &.rank-3 {
        color: #8d1f13;
        background: linear-gradient(
          190.71deg,
          #ffc0a3 -71.61%,
          #ff6521 107.09%
        );
      }

      &.rank-4 {
        color: #2b3a8b;
        background: linear-gradient(180deg, #bac6ff -142.54%, #5274ff 124.63%);
      }

      &.rank-5 {
        color: #42210a;
        background: linear-gradient(
          180deg,
          #07182a -107.03%,
          #ffab4a -107.01%,
          #b75d04 116.41%
        );
      }

      &.rank-6 {
        color: $color-black;
        background: linear-gradient(
          180deg,
          $color-rank-6 -107.01%,
          #659b15 116.41%
        );
      }

      &.rank-out {
        margin: 25px 0;
        color: $color-black;
        background: linear-gradient(
          180deg,
          $color-white -107.01%,
          gray 116.41%
        );

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: -30px;
          height: 25px;
          width: 1px;
          border: 1px dashed gray;
        }

        .rank-image-wrapper {
          display: inline-flex;
        }
      }
    }

    .address {
      white-space: nowrap;
      margin-right: 8px;
    }

    &-copy-icon {
      margin-left: 8px;
    }
  }
}

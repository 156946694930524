a {
  &.link {
    font-family: Roboto;
    font-weight: $font-regular;
    color: $color-geco;

    &:hover {
      color: $color-geco-hover;
    }

    .link-icon {
      &-left {
        margin-right: 8px;
      }

      &-right {
        margin-left: 8px;
      }
    }
  }
}

.message {
  margin-top: 8px;

  &-header {
    font-size: $font-medium;
    font-weight: $font-regular;
    flex-grow: 1;
  }

  &-description {
    font-size: $font-extra-small;

    &.error {
      color: $color-error;
    }

    &.primary {
      color: $color-white;
    }

    &.secondary {
      color: $color-white;
      opacity: 0.6;
    }
  }

  &-header + &-description {
    margin-top: 12px;
  }
}

.governance {
  font-family: 'Roboto';

  &-poll {
    & + & {
      margin-top: 40px;
    }

    .card {
      border: 1px solid $color-orange;

      &-header {
        color: $color-orange;
        border-bottom: 1px solid $color-orange;
      }
    }

    &-header {
      .accordion-icon {
        margin-right: 8px;

        &.fa-chevron-down {
          transition: $main-transition;
        }

        &.fa-chevron-down.rotated {
          transform: scaleY(-1);
        }
      }
    }

    &-body {
      .button {
        &-wrapper {
          margin-right: 10px;
        }
      }

      .answers-list {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        user-select: none;

        label.result {
          color: $color-orange;
        }

        .progress {
          .progress-bar {
            background-color: $color-white;
          }

          &.result {
            .progress-bar {
              background-color: $color-orange;
            }
          }
        }

        &:not(.voted) {
          .label {
            color: $color-white;
          }
        }

        &.voted {
          label.voted:not(.result) {
            color: $color-white !important;
          }
        }

        &.results {
          .radio-label {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@mixin level-order(
  $o0,
  $o1,
  $o2,
  $o3,
  $o4,
  $o5,
  $o6,
  $o7,
  $o8,
  $o9,
  $o10,
  $o11,
  $o12,
  $o13,
  $o14,
  $o15,
  $o16,
  $o17,
  $o18,
  $o19
) {
  &.level-0 {
    order: $o0;
  }

  &.level-1 {
    order: $o1;
  }

  &.level-2 {
    order: $o2;
  }

  &.level-3 {
    order: $o3;
  }

  &.level-4 {
    order: $o4;
  }

  &.level-5 {
    order: $o5;
  }

  &.level-6 {
    order: $o6;
  }

  &.level-7 {
    order: $o7;
  }

  &.level-8 {
    order: $o8;
  }

  &.level-9 {
    order: $o9;
  }

  &.level-10 {
    order: $o10;
  }

  &.level-11 {
    order: $o11;
  }

  &.level-12 {
    order: $o12;
  }

  &.level-13 {
    order: $o13;
  }

  &.level-14 {
    order: $o14;
  }

  &.level-15 {
    order: $o15;
  }

  &.level-16 {
    order: $o16;
  }

  &.level-17 {
    order: $o17;
  }

  &.level-18 {
    order: $o18;
  }

  &.level-19 {
    order: $o19;
  }
}

@mixin level-orders() {
  // 4 shown
  @media (min-width: $screen-desktop) {
    @include level-order(
      1,
      2,
      3,
      4,
      8,
      7,
      6,
      5,
      9,
      10,
      11,
      12,
      16,
      15,
      14,
      13,
      17,
      18,
      19,
      20
    );
  }
  // 3 shown
  @media (min-width: $screen-tablet) and (max-width: 1199px) {
    @include level-order(
      1,
      2,
      3,
      6,
      5,
      4,
      7,
      8,
      9,
      12,
      11,
      10,
      13,
      14,
      15,
      18,
      17,
      16,
      19,
      20
    );
  }

  // 2 shown
  @media (max-width: 767px) {
    @include level-order(
      1,
      2,
      4,
      3,
      5,
      6,
      8,
      7,
      9,
      10,
      12,
      11,
      13,
      14,
      16,
      15,
      17,
      18,
      20,
      19
    );
  }
}

@mixin link-config($color, $style) {
  // DESKTOP
  @media (min-width: $screen-desktop) {
    &.level {
      &-1,
      &-2,
      &-3,
      &-9,
      &-10,
      &-11,
      &-17,
      &-18,
      &-19 {
        &::after {
          @include link($color, left, $style);
        }
      }

      &-5,
      &-6,
      &-7,
      &-13,
      &-14,
      &-15 {
        &::after {
          @include link($color, right, $style);
        }
      }

      &-4,
      &-8,
      &-12,
      &-16 {
        &::before {
          @include link($color, top, $style);
        }
      }
    }
  }

  // TABLET
  @media (min-width: $screen-tablet) and (max-width: 1199px) {
    &.level {
      &-1,
      &-2,
      &-7,
      &-8,
      &-13,
      &-14 {
        &::after {
          @include link($color, left, $style);
        }
      }

      &-4,
      &-5,
      &-10,
      &-11,
      &-16,
      &-17,
      &-18 {
        &::after {
          @include link($color, right, $style);
        }
      }

      &-3,
      &-6,
      &-9,
      &-12,
      &-15,
      &-18 {
        &::before {
          @include link($color, top, $style);
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.level {
      &-1,
      &-2,
      &-5,
      &-6,
      &-9,
      &-10,
      &-13,
      &-14,
      &-17,
      &-18 {
        &::after {
          @include link($color, left, $style);
        }
      }

      &-2,
      &-4,
      &-6,
      &-8,
      &-10,
      &-12,
      &-14,
      &-16,
      &-18 {
        &::before {
          @include link($color, top, $style);
        }
      }
    }
  }
}

@mixin link($color, $direction, $style) {
  content: '';
  position: absolute;

  @if ($direction == left) {
    border-bottom: 4px $style $color;
    top: 50%;
    left: -34px;
    width: 34px;
    height: 1px;
    transform: translateY(50%);
  } @else if ($direction == right) {
    border-bottom: 4px $style $color;
    top: 50%;
    right: -34px;
    width: 34px;
    height: 1px;
    transform: translateY(50%);
  } @else if ($direction == top) {
    border-left: 4px $style $color;
    left: 50%;
    top: -64px;
    width: 1px;
    height: 64px;
    transform: translateX(50%);
  } @else if ($direction == bottom) {
    border-left: 4px $style $color;
    left: 50%;
    bottom: -64px;
    width: 1px;
    height: 64px;
    transform: translateX(50%);
  }
}

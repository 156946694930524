.nft-address-link {
  @media screen and (max-width: $screen-mobile-big) {
    &-input,
    &-button {
      width: 100%;
    }

    &-input {
      margin-bottom: 16px;
    }

    &-button {
      margin-right: 20px !important;
    }
  }
}

.staking-pool {
  & + & {
    margin-top: 40px;
  }

  &.single-asset-staking {
    .pool-type {
      color: $color-orange;
    }
  }

  &.lp-staking {
    .pool-type {
      color: $color-blue;
    }
  }

  &.stablecoin-staking {
    .pool-type {
      color: $color-cyan;
    }
  }

  &-header {
    font-size: $font-extra-large;
    margin-bottom: 24px;
    text-transform: uppercase;
    font-weight: $font-bold;

    .pool-type {
      margin-right: 8px;
    }
  }
}

.staking-pool-item {
  font-family: Roboto;

  &.allocation-pool-item {
    .staking-pool-item-details {
      margin-bottom: 20px;
    }

    .staking-pool-item-header {
      display: block;
    }
  }

  &.inactive {
    .staking-pool-item-header {
      .name {
        filter: grayscale(1);
      }
    }
  }

  & + & {
    margin-top: 24px;
  }

  .card:first-child {
    .single-asset-staking & {
      border-color: $color-orange;
    }

    .lp-staking & {
      border-color: $color-blue;
    }

    .stablecoin-staking & {
      border-color: $color-cyan;
    }
  }

  &-header {
    @include flex-between();
    flex-basis: 100%;
    font-style: normal;
    font-size: $font-small;

    .accordion-icon {
      margin-right: 8px;

      .single-asset-staking & {
        color: $color-orange;
      }

      .lp-staking & {
        color: $color-blue;
      }

      &.fa-chevron-down {
        transition: $main-transition;
      }

      &.fa-chevron-down.rotated {
        transform: scaleY(-1);
      }
    }

    .name {
      font-weight: $font-bold;
      flex-basis: 100%;

      &-icon {
        filter: grayscale(1);
        margin: 0 5px;
      }
    }

    .apy {
      font-weight: $font-bold;
      white-space: nowrap;
      text-align: center;

      &-limit {
        font-size: 10px;
        font-weight: normal;
      }

      .single-asset-staking & {
        color: $color-orange;
      }

      .lp-staking & {
        color: $color-blue;
      }

      .stablecoin-staking & {
        color: $color-cyan;
      }

      &-question-icon {
        margin-left: 8px;
      }
    }

    .total {
      color: $color-white;
      font-weight: $font-regular;
      flex-basis: 100%;
      text-align: right;
    }
  }

  &-body {
    .staking-pool-item-details {
      @include flex-between();

      & + .staking-pool-item-details {
        margin-top: 16px;
      }
    }

    .pills {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .tabs {
      .tab-content {
        font-family: Roboto;
        font-size: $font-small;
        color: $color-white;

        .approve-section {
          @include flex-between();
        }

        .approve-section,
        .stake-section,
        .unstake-section,
        .move-bond-section {
          &-description {
            margin-bottom: 24px;
          }

          &-actions {
            @include flex-between();
          }
        }

        .stake-section,
        .unstake-section,
        .move-bond-section {
          .left {
            display: flex;
            flex-basis: 50%;
          }

          .right {
            flex-grow: 1;
            flex-basis: 50%;
            padding-left: 12px;
          }

          .playnity-input-wrapper {
            width: 100%;
          }
        }

        .claim-section {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-around;
          padding: 16px 50px;
          border: 1px $color-aquamarine solid;
          border-radius: 5px;
          height: 150px;
          background-repeat: no-repeat;
          font-family: Roboto;
          background-position: left;
          background-size: cover;

          &.claimable {
            background-image: url(../../../assets/images/claim.png);
          }

          &.not-claimable {
            background-image: url(../../../assets/images/no-claim.png);
          }

          &-info {
            margin-bottom: 16px;

            &-header {
              font-size: $font-medium;
            }

            &-details {
              font-size: $font-small;
              color: $color-white;

              .text {
                opacity: 0.6;
                margin-right: 4px;
              }

              .amount {
                opacity: 1;
              }
            }
          }

          &-button {
            width: calc(100% - 40px);
          }
        }

        .move-bond-section {
          &-btn {
            margin-right: 20px;
          }
        }
      }
    }
  }

  @media (max-width: $screen-tablet) {
    &-header {
      flex-wrap: wrap;

      .name,
      .apy {
        flex-basis: 50%;
      }

      .apy {
        text-align: right;
      }

      .total {
        margin-top: 12px;
        text-align: left;
      }
    }

    &-details {
      flex-wrap: wrap;

      .data-item {
        flex-direction: row;
      }
    }

    &-body {
      .tabs {
        .tab-content {
          .approve-section {
            flex-wrap: wrap;
            justify-content: flex-end;
          }

          .claim-section {
            display: block;
            margin-right: 0;
            padding: 16px 24px;

            &-info {
              padding: 0;
            }

            &-button {
              width: calc(100% - 20px);
            }

            &.claimable,
            &.not-claimable {
              background-image: url(../../../assets/images/claim-bg-horizontal.png);
              background-position: bottom;
            }
          }

          .approve-section,
          .stake-section,
          .unstake-section {
            &-actions {
              flex-wrap: wrap-reverse;
            }

            .left,
            .right {
              flex-basis: 100%;
              width: 100%;
            }

            .left {
              margin-top: 16px;

              .btn {
                width: 50%;
                margin-right: 20px;
              }
            }

            .right {
              padding-left: 0;
            }

            .playnity-input-wrapper {
              width: 50%;
            }
          }

          .move-bond-section {
            &-actions {
              flex-wrap: wrap;
            }

            &-btn {
              width: 100%;
              margin-top: 16px;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: $screen-mobile-big) {
    &-body {
      .tabs {
        .tab-content {
          .stake-section,
          .unstake-section {
            .left {
              flex-wrap: wrap;

              .playnity-input-wrapper {
                width: 100%;
                margin-bottom: 16px;
              }

              .btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.bonded-tokens {
  margin-top: 24px;
  border-radius: $main-border-radius;
  overflow: hidden;

  &.card .card-body {
    background-color: $color-pill-background;
    padding: 24px;
  }

  &-header {
    @include flex-centered();
  }

  &-image {
    width: 130px;
    height: auto;
  }

  &-message {
    flex-grow: 1;
    margin: 0 10px;
  }

  &-table {
    &-header {
      margin-top: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $color-white;
      opacity: 0.6;
      padding: 12px;

      .amount {
        text-align: right;
      }

      .amount,
      .time {
        flex-basis: 50%;
      }

      .time-amount-separator {
        display: none;
      }
    }

    &-item {
      @include flex-between();
      flex-wrap: wrap;
      padding: 12px;
      color: $color-white;

      .amount {
        text-align: right;
      }

      &.ready {
        .amount,
        .time {
          color: $color-aquamarine;
          font-weight: $font-bold;
        }
      }

      .amount,
      .time {
        color: $color-white;
        flex-basis: 50%;
      }

      &:nth-child(2n) {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 5px;
      }
    }
  }

  &-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;

    .btn-fee,
    .btn-no-fee {
      padding: 10px 35px !important;
    }

    .fee-text {
      text-align: center;
      margin-top: 4px;
      font-size: $font-extra-small;
      color: white;
      opacity: 0.6;
    }
  }

  @media (max-width: $screen-tablet) {
    &-message {
      margin-left: 0;
      margin-right: 0;

      .message {
        margin-top: 0;
      }
    }

    &-image {
      display: none;
    }

    &-table {
      &-header {
        display: block;
        padding: 12px 0;

        .time,
        .amount {
          display: inline;
        }

        .time-amount-separator {
          display: inline;
        }
      }
    }

    &-buttons {
      display: block;

      .playnity-button-wrapper,
      .btn-fee {
        width: calc(100% - 10px);
        margin: 8px 0 0 0 !important;
        left: 5px;
      }
    }
  }
}

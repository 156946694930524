.header {
  .logo {
    img {
      width: 190px;
    }

    &-mobile {
      display: none;
    }
  }

  .chain-logo {
    height: 30px;
    width: auto;
    margin-left: 8px;
  }

  &-wallet-section {
    @include flex-centered();

    .wallet-address,
    .wallet-connect {
      text-transform: uppercase;
      padding-top: 1px;
      font-size: 14px;
      font-weight: $font-bold;
      margin-left: 16px;
      color: #edeaea;
    }

    .wallet-connect {
      color: $color-geco;
      cursor: pointer;

      &:hover {
        color: $color-geco-hover;
      }
    }

    .fa-power-off {
      transition: $main-transition;
      cursor: pointer;
      margin-left: 16px;
      color: $color-error;

      &:hover {
        filter: brightness(0.9);
      }
    }

    .navbar-wrap {
      flex-grow: 0;
      text-align: right;
    }
  }

  @media (max-width: 1199.98px) {
    .logo {
      display: none;
    }

    .logo-mobile {
      display: initial;

      img {
        width: 47px;
      }
    }

    &-wallet-section {
      position: relative;
      right: 50px;
      color: #fff;
      margin: 0;
      padding: 10px 5%;
      text-align: left;
      text-decoration: none;
      text-transform: capitalize;
      font-size: 16px;

      .wallet-address {
        .fa-wallet {
          margin: 0 8px 0 0;
        }
      }
    }
  }
}
